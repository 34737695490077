import React from "react";
import { css } from "@emotion/react";
import Seo from "components/seo";
import Layout from "components/layout";
import { rhythm } from "utils/typography";
import { mq } from "utils/helper";

function ChemicalCollection() {
    return ( <Layout>
	<Seo metadata={{ title: "Chemical Weapons Database" }} />
	<div  css={{
            maxWidth: "1200px",
            margin: "8rem auto",
            [mq[3]]: {
              margin: `0 ${rhythm(1)}`
            },
          }}>
	    <p css={{
		fontSize: "2rem",
		textAlign: "center"
	    }}>
		Syrian Archive is updating this dataset. It will be available again soon.
If you have any specific inquiries, please reach out to us at info@syrianarchive.org</p>
	</div>
	     </Layout>);
}

export default ChemicalCollection;
